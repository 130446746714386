
                        import Fragment from '@fyn-software/component/fragment.js';
                        import { initialize } from '@fyn-software/component/template.js';
                        import For from '@fyn-software/component/directive/for.js';
                        import If from '@fyn-software/component/directive/if.js';
                        import Switch from '@fyn-software/component/directive/switch.js';
                        
                        const map = {};
                        __map__
                        
                        await initialize(map, {
                            'for': For,
                            'if': If,
                            'switch': Switch,
                        }, [])
                        
                        export default map;